/* src/components/Portfolio.css */

.portfolio {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.filters {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
}

.grid img {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.grid img:hover {
    transform: scale(1.05);
}

.pagination {
    text-align: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.pagination button:hover {
    background-color: #0056b3;
}

.pagination .active {
    background-color: #0056b3;
}

body.modal-open {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
}

.sort-options {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sort-options label {
    margin-right: 10px;
    font-size: 16px;
    font-weight: bold;
}

.sort-options select {
    padding: 5px 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.sort-options select:focus {
    border-color: #007bff;
    outline: none;
}

.filters-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.floating-button {
    position: fixed;
    bottom: 80px; /* 调整位置，使其高于页脚 */
    right: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;
    z-index: 1000; /* 确保按钮在页脚之上 */
}

.floating-button:hover {
    background-color: #0056b3;
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .portfolio {
        padding: 10px;
    }

    .grid img {
        border-radius: 5px;
    }

    .pagination button {
        padding: 8px 16px;
        font-size: 14px;
    }

    .sort-options label, .filters label {
        font-size: 14px;
    }

    .sort-options select, .filters select, .filters input[type="date"] {
        font-size: 14px;
        padding: 5px;
    }
}

@media (min-width: 769px) and (max-width: 1080px) {
    .grid img {
        border-radius: 8px;
    }

    .pagination button {
        padding: 10px 18px;
        font-size: 15px;
    }

    .sort-options label, .filters label {
        font-size: 15px;
    }

    .sort-options select, .filters select, .filters input[type="date"] {
        font-size: 15px;
        padding: 5px 8px;
    }
}

@media (min-width: 1081px) {
    .grid img {
        border-radius: 10px;
    }

    .pagination button {
        padding: 10px 20px;
        font-size: 16px;
    }

    .sort-options label, .filters label {
        font-size: 16px;
    }

    .sort-options select, .filters select, .filters input[type="date"] {
        font-size: 16px;
        padding: 5px 10px;
    }
}
