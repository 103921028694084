/* src/components/Footer.css */
.footer {
    position: relative; /* Modify to relative positioning */
    width: 100%;
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
    z-index: 500; /* Ensure the footer is on top */
    display: flex; /* Add flexbox layout */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
}

.footer p {
    margin: 0 10px; /* Add margin to create space between items */
    padding: 0;
}
