/* src/components/Filters.css */

.filters {
    display: flex;
    flex-wrap: wrap; /* 允许子元素换行 */
    justify-content: center; /* 居中对齐 */
    margin-bottom: 20px;
    width: 100%; /* 确保宽度为100% */
    gap: 10px; /* 子元素之间的间距 */
}

.filters label {
    font-size: 16px;
    font-weight: bold;
}

.filters select,
.filters input[type="date"] {
    padding: 5px 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.filters select:focus,
.filters input[type="date"]:focus {
    border-color: #007bff;
    outline: none;
}

@media (max-width: 768px) {
    .filters {
        flex-direction: column; /* 小屏幕上使用列方向 */
    }

    .filters label {
        font-size: 14px;
    }

    .filters select,
    .filters input[type="date"] {
        font-size: 14px;
        padding: 5px;
    }
}

@media (min-width: 769px) and (max-width: 1080px) {
    .filters {
        flex-direction: row; /* 中等屏幕上使用行方向 */
    }

    .filters label {
        font-size: 15px;
    }

    .filters select,
    .filters input[type="date"] {
        font-size: 15px;
        padding: 5px 8px;
    }
}

@media (min-width: 1081px) {
    .filters {
        flex-direction: row; /* 大屏幕上使用行方向 */
    }

    .filters label {
        font-size: 16px;
    }

    .filters select,
    .filters input[type="date"] {
        font-size: 16px;
        padding: 5px 10px;
    }
}
