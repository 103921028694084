/* src/components/ImageModal.css */

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    overflow: auto;
    max-height: 90vh;
    width: auto;
}

.modal.landscape {
    width: 90vw;
}

.modal.portrait {
    max-width: 90vw;
    width: auto;
}

.modal-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-image {
    width: 100%;
    max-height: 70vh;
    object-fit: contain;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: rgba(0,0,0,0.3) 6px 6px 12px;
}

.exif-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid #ccc;
    font-size: 13px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0,0,0,0.3) 6px 6px 12px;
}

.exif-brand {
    font-size: 16px;
    flex: 1;
    text-align: left;
    padding-left: 2%;
    font-weight: bold;
}

.exif-details {
    flex: 3;
    text-align: right;
    padding-right: 2%;
    margin: 0 0 0 10px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
}

.close-button,
.download-button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.close-button {
    background-color: #ff4d4f;
    color: white;
}

.close-button:hover {
    background-color: #ff7875;
}

.download-button {
    background-color: #28a745;
    color: white;
    text-decoration: none;
}

.download-button:hover {
    background-color: #218838;
}

.password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.password-form label {
    font-size: 14px;
    margin-bottom: 10px;
}

.password-form input {
    padding: 10px;
    font-size: 14px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.password-form button {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.password-form button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}

@media (max-width: 768px) {
    .modal {
        width: 95%;
        max-height: 85vh;
    }

    .modal-image {
        max-height: 50vh;
    }

    .close-button, .download-button {
        padding: 8px 16px;
        font-size: 12px;
    }
}

@media (min-width: 769px) and (max-width: 1080px) {
    .modal-image {
        max-height: 60vh;
    }

    .close-button, .download-button {
        padding: 12px 24px;
        font-size: 14px;
    }
}

@media (min-width: 1081px) {
    .modal-image {
        max-height: 70vh;
    }

    .close-button, .download-button {
        padding: 14px 28px;
        font-size: 14px;
    }
}
@media (max-width:1080px) {
    .modal{
        width: 80%;
    }
    .exif-info {
        font-size: 8px;
        padding: 0 0;
    }
    .exif-brand {
        font-size: 11px;
    }
    .exif-details p{
        margin: 5px 0 5px 0;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

body.modal-open .content {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}
