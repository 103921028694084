.details {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.details h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}

.filter-container {
    text-align: center;
    margin-bottom: 20px;
}

.filter-container label {
    margin-right: 10px;
    font-size: 16px;
}

.filter-container select,
.filter-container input {
    padding: 5px 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.filter-container select:focus,
.filter-container input:focus {
    border-color: #007bff;
    outline: none;
}

.details table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.details th, .details td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    font-size: 14px;
}

.details th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    font-size: 16px;
}

.details tr:nth-child(even) {
    background-color: #f2f2f2;
}

.details tr:hover {
    background-color: #eaeaea;
}

@media (max-width: 768px) {
    .details {
        padding: 10px;
    }

    .details h2 {
        font-size: 20px;
    }

    .filter-container label {
        display: block;
        margin-bottom: 10px;
    }

    .filter-container input {
        width: 100%;
    }

    .details table {
        display: none; /* 隐藏表格 */
    }

    .log-card {
        background-color: white;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 15px;
        padding: 15px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .log-card-header {
        font-weight: bold;
        margin-bottom: 10px;
        color: #007bff;
    }

    .log-card-body {
        font-size: 14px;
        line-height: 1.5;
    }

    .log-card-body p {
        margin: 5px 0;
    }
}
