/* src/App.css */
.App {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%; /* 确保应用宽度为100% */
  overflow: hidden; /* 确保背景图片不滚动 */
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1; /* 确保背景图片在最底层 */
  transition: filter 0.3s ease; /* 过渡效果 */
}

.content {
  flex: 1;
  width: 100%; /* 确保内容宽度为100% */
  box-sizing: border-box; /* 确保padding和border不会影响宽度 */
  position: relative;
  z-index: 1; /* 确保内容在背景图片之上 */
}
